import ProImg from "../assets/classified.PNG"
import ProImg2 from "../assets/Dapvenueadmin.PNG"
import ProImg3 from "../assets/marketplace.PNG"
const ProjectCardData=[
    {
        imgsrc:ProImg,
        title:"Classified",
        text:"The project was designed based on OLX. On the website, users can see ads of different categories, and each category has various products. I worked on two admin panels: Super Admin and Advertiser Panel. Super Admin managed everything from the admin panel. Five categories were added, and each category had different attributes. Users could add products and pay for them using Stripe and PayPal. These ads were featured, and I managed these featured ads. I also added a billing/invoice module that allows users to download PDF invoices for these featured ads",
        view:"https://alhikmahinternational.org/classified/public/admin",
    },
    {
        imgsrc:ProImg2,
        title:"Dapsocially Venue Admin Panel",
        text:"In the venue admin panel, venues and events added from Dapsocially.app could be viewed in the module. I added screens, capacity, and area type fields to venues and events. When an admin clicks on a venue, a modal popup opens where they can add details for these fields. There is also an option to add the venue to the listing or remove it from the listing. The same applies to events where a popup opens to store the field data and the option to add or remove the event from the listing.The listing, bidding, and channel modules were also added",
        view:"https://dapsocially.app/dapsocially_venue_admin_panel/admin-login",
    },
    {
        imgsrc:ProImg3,
        title:"Dapsocially Advertising Marketplace",
        text:"In Dapsocially Advertising Website, venues and events added in the listing were shown. There was a Home Page where all were shown, a Venues page where 5 venues/events were shown, and on load more, the next 4 were displayed. Advertisers registered, logged in, and saw the venues/events.When an advertiser clicked on a venue/event on which they placed a bid, the venue/event page opened, displaying the current bid price, timer, and an option to place a bid.When the advertiser clicked on place a bid the bid page opened. ",
        view:"https://dapsocially.app/marketing/",
    }
];

export default ProjectCardData;
