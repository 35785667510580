import "./HeroImageStyles.css";
import React from 'react'
import IntroImg from "../assets/port2.jpg"
import { Link } from "react-router-dom";
const HeroImage = () => {
  return (
    <div className="hero">
      <div className="mask"> 
       <img className="into-image" src={IntroImg} alt="IntroImage"/>
      </div>
      <div className="content">
        <p>HI , I'M A FREELANCER</p>
        <h1>Web Developer</h1>
        <div>
            <Link to="/project" className="btn">Projects</Link>
            <Link to="/contact" className="btn btn-light">Contact</Link>
        </div>
      </div>

    </div>
  )
}

export default HeroImage
