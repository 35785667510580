import { Link } from 'react-router-dom'
import './AboutContentStyles.css'
import React from 'react'
import laravel1 from "../assets/Laravel1.png"
import laravel2 from "../assets/Laravel2.png"
const AboutContent = () => {
  return (
    <div className='about'>
      <div className='left'>
        <h1>Who Am I?</h1>
        <p className='about-p'>As a Laravel developer, I started my career in software development and specializes in using the Laravel PHP framework for web application development. I have some experience with other programming languages and web development technologies, but I still learning the ins and outs of Laravel and its various components . As a junior developer, I am eager to learn and grow skills, collaborate with team members, and contribute to the development of high-quality web applications.</p>
        <Link to='/contact'>
            <button className='btn'>Contact</button>
        </Link>
      </div>
      <div className='right'>
        <div className='img-container'>
            <div className='img-stack top'>
                <img src={laravel1} alt="true" className="img"></img>
            </div>
            <div className='img-stack bottom'>
                <img src={laravel2} alt="true" className="img"></img>
            </div>
        </div>
      </div>
    </div>
  )
}

export default AboutContent
